
import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { ActivityAccount, CreateActivityAccountPayload, PatchActivityAccountPayload, TeamFunctions } from "@/store/modules/activity-account.store";
import { IndexAccountPayload } from "@/store/modules/account.store";

@Component({
    components: {
        userImage: () => import("@/components/general/user-image.vue"),
        userImageWrapper: () => import("@/components/general/user-image-wrapper.vue"),
        userSelect: () => import("@/components/forms/user-select.vue"),
    },
})
export default class AssignUser extends Vue {
    @Action("activity-account/create") createActivityAccount!: (payload: CreateActivityAccountPayload) => Promise<ActivityAccount>;
    @Action("activity-account/patch") patchActivityAccount!: (payload: PatchActivityAccountPayload) => Promise<ActivityAccount>;
    @Action("activity-account/delete") deleteActivityAccount!: (payload: number) => Promise<void>;
    @Action("account/indexForCurrentOffice") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;

    @Getter("account/all") accounts!: Account[];

    @Prop({ required: true }) activity!: Activity;
    @Prop({ default: "h-[32px] w-[32px]" }) sizeClass!: string;
    @Prop({ default: "center" }) direction!: "right" | "left" | "center";
    @Prop({ required: true }) teamFunction!: TeamFunctions;

    assignedUserIds: number[] = this.activity.assigned_to.filter((u) => u.type === this.teamFunction).map((v) => v.id);

    get users() {
        return this.accounts.map((a) => {
            return { first_name: a.first_name, last_name: a.last_name, id: a.id, media: a.media };
        });
    }

    async assignUserToRole(newAssignees: number[]) {
        let assignees: Array<{ id: number; relation_id: number }> = this.activity.assigned_to.filter((u) => u.type === this.teamFunction);

        try {
            if (newAssignees.length === 0) {
                for (const assignee of assignees) {
                    await this.deleteActivityAccount(assignee!.relation_id);

                    this.assignedUserIds = newAssignees;

                    return;
                }
            }

            if (assignees.length === 0) {
                for (const assignee_id of newAssignees) {
                    await this.createActivityAccount({ account_id: assignee_id, activity_id: this.activity.id, type: this.teamFunction });
                }

                this.assignedUserIds = newAssignees;

                this.$toast.open({ message: this.$t("toasts.user_assign.success") as string, type: "success", position: "bottom-right" });

                return;
            }

            if (newAssignees.length >= assignees.length) {
                for (let i = 0; i < newAssignees.length; i++) {
                    if (assignees[i]) {
                        await this.patchActivityAccount({ account_id: newAssignees[i], id: assignees[i].relation_id, type: this.teamFunction });
                    } else {
                        await this.createActivityAccount({ account_id: newAssignees[i], activity_id: this.activity.id, type: this.teamFunction });
                    }
                }

                this.assignedUserIds = newAssignees;

                this.$toast.open({ message: this.$t("toasts.user_assign.success") as string, type: "success", position: "bottom-right" });

                return;
            }

            if (assignees.length > newAssignees.length) {
                for (let i = 0; i < assignees.length; i++) {
                    if (newAssignees[i]) {
                        await this.patchActivityAccount({ account_id: newAssignees[i], id: assignees[i].relation_id, type: this.teamFunction });
                    } else {
                        await this.deleteActivityAccount(assignees[i].relation_id);
                    }
                }

                this.assignedUserIds = newAssignees;

                this.$toast.open({ message: this.$t("toasts.user_assign.success") as string, type: "success", position: "bottom-right" });

                return;
            }
        } catch (e) {
            this.assignedUserIds = this.activity.assigned_to.filter((u) => u.type === this.teamFunction).map((v) => v.id);

            this.$toast.open({ message: this.$t("toasts.user_assign.error") as string, type: "error" });
        }
    }

    async handleOpen() {
        if (this.accounts.length) {
            return;
        }

        this.indexAccounts({ q: { or: { roles: ["administrator", "user"] } }, per_page: 99 });
    }
}
